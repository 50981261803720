<template>
  <button
    ref="dropzoneRef"
    class="Dropzone"
    :disabled="disabled || filesUploading"
    :class="{ isHovered, isOverDropZone, isError }"
  >
    <template v-if="!filesUploading">
      <div class="DropzoneContent">
        <Icon v-if="icon" class="DropzoneIcon" :icon="icon"></Icon>
        <slot></slot>
      </div>
      <div v-if="isLoading" class="DropzoneProgress">
        <Progress :model-value="progress" />
        {{ progress }}%
      </div>
    </template>
    <template v-else>
      <LoadingSpinner size="large" />
      <div>Fichiers en cours d'ajout...</div>
    </template>
  </button>
</template>

<script setup lang="ts">
import { isNumber } from "lodash-es";
import type { DropzoneProps } from "./types";

const props = withDefaults(defineProps<DropzoneProps>(), {
  icon: mapIcon.upload_cloud,
  progress: undefined,
  isError: false,
  width: "100%",
  height: "128px",
  disabled: false,
  filesUploading: false,
});

const emit = defineEmits<{ onDrop: [files: File[]] }>();

const dropzoneRef = ref<HTMLDivElement>();
const isHovered = useElementHover(dropzoneRef);
const { isOverDropZone } = useDropZone(dropzoneRef, {
  onDrop: (files) => {
    if (files) emit("onDrop", files);
  },
});

const isLoading = computed(() => isNumber(props.progress));

provide("isLoading", isLoading);
</script>

<style lang="scss">
.Dropzone {
  height: v-bind(height);
  width: v-bind(width);
  border-radius: 10px;
  border: 1px dashed var(--gray-5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-inline: 32px;
  cursor: pointer;
  flex-shrink: 0;

  &.isHovered {
    border: 1px solid var(--gray-4);

    .DropzoneText {
      color: var(--gray-2);
    }
  }

  &.isOverDropZone {
    background: var(--blue-light);
    border: 1px solid var(--blue);

    .DropzoneText,
    .DropzoneIcon {
      color: var(--blue);
    }
  }

  &.isError {
    border-color: var(--danger);
  }

  &:focus {
    border: 1px solid var(--border-focus);
    outline: 2px solid var(--border-focus-outline);
  }

  &:disabled {
    background: var(--surface-disabled);
    border: 1px solid var(--border);
    cursor: not-allowed;
  }
}

.DropzoneContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.4rem;
  padding-block: 1.5rem;
}

.DropzoneProgress {
  display: flex;
  align-items: center;
  width: 100%;
  size: 16px;
  color: var(--gray-3);
  gap: 8px;
}

.DropzoneIcon {
  color: var(--gray-2);
}
</style>
